import React from 'react';
import { imgOvertime_1024Png } from '~/src/images';
import config from '~/src/lib/config';
import styles from '~/src/lib/styles.json';
import CookieConsent from '~/src/overtime-lib/src/www/components/CookieConsent';
import './Html.scss';

export default function Html({
	assets = {},
	children = null,
	title = 'Overtime',
}: {
	assets: { [key: string]: any };
	children: React.ReactNode;
	title: string;
}) {
	const description =
		"Overtime is the leading brand for the next generation of sports fans. As both a publisher and an influencer, we've built the inclusive world of sports we all wish was the norm by giving a platform to our community to be seen AND heard.";

	const absoluteLogoUrl = imgOvertime_1024Png.startsWith('http')
		? imgOvertime_1024Png.replace('http://localhost:8080/', 'https://overtime.tv/')
		: `https://overtime.tv${imgOvertime_1024Png}`;

	return (
		<html lang="en">
			<head>
				<title>{title}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta charSet="UTF-8" />
				<meta name="google-site-verification" content="_Npfn_L-weRdy7khF5vLyZVtGDv7o59P15vRfRpuFDw" />
				<meta name="google-site-verification" content="2kA3U3XEwCVR8zHoKOR9o12DITn1nJApUIJqQ1CKH-s" />
				<meta name="google-site-verification" content="AKheGLF0n028K_um_R3PAQHuImh2e_WEe3RXqzo5NaA" />
				<meta property="fb:app_id" content={config('OVERTIME_FB_APP')} />
				<meta property="og:site_name" content="OVERTIME" />
				<meta property="og:description" content={description} />
				<meta property="og:image" content={absoluteLogoUrl} />
				<meta name="description" content={description} />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				{/* <link rel="manifest" href="/site.webmanifest" /> */}
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color={styles.COLOR.BLACK} />
				<meta name="msapplication-TileColor" content={styles.COLOR.BLACK} />
				<meta name="theme-color" content={styles.COLOR.BLACK} />

				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: `{
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name": "Overtime",
                        "url": "https://overtime.tv",
                        "logo": "${absoluteLogoUrl}",
                        "sameAs" : [ "https://www.facebook.com/getovertime", "https://www.instagram.com/overtime", "https://twitter.com/overtime", "https://www.youtube.com/channel/UCPHKMJbis1c0R7d7WJQ35tA"]
                        }`,
					}}
				/>

				<meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
				<style>{`
					
				`}</style>
				<link rel="preload" as="style" href="https://use.typekit.net/fxu0kto.css" />
				<link rel="stylesheet" href="https://use.typekit.net/fxu0kto.css" />
				<link rel="stylesheet" href="/public/fortawesome.css" />
				<link rel="stylesheet" href="/public/app.css" />
				{Object.values(assets)
					.filter((a) => a.endsWith('css'))
					.map((a) => (
						<link rel="stylesheet" key={a} href={a} />
					))}
			</head>
			<body>
				<div id="root">{children}</div>
				<CookieConsent googleMeasurementId="G-M053431JXF" isDark={true} />
				<script
					dangerouslySetInnerHTML={{
						__html: `window.assetManifest = ${JSON.stringify(assets)};window.fetch = window.fetch.bind(window);`,
					}}
				/>
				{Object.values(assets)
					.filter((a) => a.endsWith('js'))
					.map((a) => (
						<script key={a} src={a} />
					))}
				<script src="/public/app.js" />
			</body>
		</html>
	);
}
