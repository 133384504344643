import React from 'react';
import Html from '~/src/components/Html';

/*
Add all shared elements here to fix Parcel being dumb
This component should never actually be used
*/

const Common = () => (
	<>
		{/* Parcel is trash and doesn't bundle CSS for components inside contexts */}
		<Html assets={{}} children={''} title={''} />
	</>
);

export default Common;
