import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Spinner from './Spinner';
import './Suspended.scss';

const Suspended = ({ children }) => (
	<ErrorBoundary
		fallback={
			<p className="Error">
				<FontAwesomeIcon icon={faTriangleExclamation} /> Unknown error.
			</p>
		}
	>
		<Suspense
			fallback={
				<div className="Suspended">
					<Spinner />
				</div>
			}
		>
			{children}
		</Suspense>
	</ErrorBoundary>
);

export default Suspended;
