// Maintained in overtime-lib
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter, Routes as ReactRoutes } from 'react-router-dom';
//@ts-ignore
import App from '~/src/components/App';
//@ts-ignore
import { Routes } from '~/src/www/routes';

declare global {
	interface Window {
		assetManifest?: any;
	}
}

const BrowserRoutes = () => (
	<BrowserRouter>
		<ReactRoutes>{Routes}</ReactRoutes>
	</BrowserRouter>
);

if (typeof window !== 'undefined') {
	hydrateRoot(
		document,
		<App assets={window.assetManifest}>
			<BrowserRoutes />
		</App>,
		{
			onRecoverableError: (...args) => {
				if (String(args[0]).startsWith('Minified React')) {
					throw args[0] + '\n\nComponent stack:\n' + args[1]?.componentStack;
				}
				throw args[0];
			},
		},
	);
}
