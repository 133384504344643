{
	"COLOR": {
		"BRAND_PRIMARY": "#141923",
		"BRAND_PRIMARY_DARK": "#0c111b",
		"BRAND_PRIMARY_MODAL": "rgba(20,25,35,0.98)",
		"BRAND_PRIMARY_OVERLAY": "rgba(20,25,35,0.7)",
		"BRAND_SECONDARY": "#FF6E40",
		"BRAND_LINK": "#0FB5A7",
		"OFFBLACK": "#333",
		"BLACK": "#1e1e1e",
		"WHITE": "#ffffff",
		"OFFWHITE": "#fafafa",
		"DESATURATED_WHITE_BORDER": "rgba(255,255,255,0.2)",
		"DESATURATED_WHITE_TEXT": "rgba(255,255,255,0.7)",
		"DESATURATED_WHITE_BACKGROUND": "rgba(255,255,255,0.8)",
		"DESATURATED_BLACK_BORDER": "rgba(0,0,0,0.1)",
		"DESATURATED_BLACK_TEXT": "rgba(0,0,0,0.4)",
		"DESATURATED_BLACK_BACKGROUND": "rgba(0,0,0,0.3)",
		"SHADOW": "rgba(0,0,0,0.25)",
		"INNER_SHADOW": "rgba(0,0,0,0.05)"
	},
	"FONT": {
		"BRAND": "alternate-gothic-no-3-d, sans-serif",
		"MAIN": "source-sans-pro, Helvetica Neue, Helvetica, Arial, sans-serif",
		"HIGHLIGHT": "roboto-condensed, Helvetica Neue, Helvetica, Arial, sans-serif"
	},
	"SIZE": {
		"GAP": "var(--gap)",
		"GAP_QUARTER": "calc(var(--gap) / 4)",
		"GAP_HALF": "calc(var(--gap) / 2)",
		"GAP_2X": "calc(var(--gap) * 2)",
		"GAP_3X": "calc(var(--gap) * 3)",
		"GAP_4X": "calc(var(--gap) * 4)",
		"GAP_6X": "calc(var(--gap) * 6)",
		"ROW_GAP": "var(--row-gap)",
		"LARGE_MOBILE_WIDTH": "640px",
		"TABLET_WIDTH": "768px",
		"DESKTOP_WIDTH": "990px",
		"CONTENT_WIDTH": "1330px"
	}
}
