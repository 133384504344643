import React, { createElement, lazy } from 'react';
import { Route } from 'react-router-dom';
import Suspended from '~/src/components/Suspended';

const Index = lazy(() => import('~/src/components/pages/Index'));
const About = lazy(() => import('~/src/components/pages/About'));
const Terms = lazy(() => import('~/src/components/pages/Terms'));
const TermsCamera = lazy(() => import('~/src/components/pages/TermsCamera'));
const Privacy = lazy(() => import('~/src/components/pages/Privacy'));
const Campaigns = lazy(() => import('~/src/components/pages/Campaigns'));
const Copyright = lazy(() => import('~/src/components/pages/Copyright'));
const Accessibility = lazy(() => import('~/src/components/pages/Accessibility'));
const UserGuidelines = lazy(() => import('~/src/components/pages/UserGuidelines'));
const Brand = lazy(() => import('~/src/components/pages/Brand'));
const Gaming = lazy(() => import('~/src/components/pages/Gaming'));
const NotFound = lazy(() => import('~/src/components/pages/NotFound'));
const Contact = lazy(() => import('~/src/components/pages/Contact'));
const VideographerApplication = lazy(() => import('~/src/components/pages/VideographerApplication'));

//This is a stupid hack to get Parcel to put shared element styles in app.css where they belong
import Common from '~/src/components/Common';
//@ts-ignore
const _ = Common;

const LazyElement = ({ element }: { element: React.LazyExoticComponent<any> }) => (
	<Suspended>{createElement(element)}</Suspended>
);

export const Routes = (
	<Route>
		<Route path="/" element={<LazyElement key="Index" element={Index} />} />
		<Route path="/about" element={<LazyElement key="About" element={About} />} />
		<Route
			path="/videographer_application"
			element={<LazyElement key="VideographerApplication" element={VideographerApplication} />}
		/>
		<Route path="/contact" element={<LazyElement key="Contact" element={Contact} />} />
		<Route path="/terms" element={<LazyElement key="Terms" element={Terms} />} />
		<Route path="/terms_camera" element={<LazyElement key="TermsCamera" element={TermsCamera} />} />
		<Route path="/privacy" element={<LazyElement key="Privacy" element={Privacy} />} />
		<Route path="/campaigns" element={<LazyElement key="Campaigns" element={Campaigns} />} />
		<Route path="/copyright" element={<LazyElement key="Copyright" element={Copyright} />} />
		<Route path="/accessibility" element={<LazyElement key="Accessibility" element={Accessibility} />} />
		<Route path="/user_guidelines" element={<LazyElement key="UserGuidelines" element={UserGuidelines} />} />
		<Route path="/brand" element={<LazyElement key="Brand" element={Brand} />} />
		<Route path="/gaming" element={<LazyElement key="Gaming" element={Gaming} />} />
		<Route path="*" element={<LazyElement key="NotFound" element={NotFound} />} />
	</Route>
);
