import React, { useEffect, useState } from 'react';
import './CookieConsent.scss';

const Logo = ({ color }: { color: string }) => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 50 60">
		<path
			d="M45.1,6.5C41.5,2.4,36.2,0,30.5,0H25h-5.5c-5.7,0-11,2.4-14.6,6.5C1.7,10,0,14.6,0,19.5v21.1c0,7,3,11.4,5.6,14
	C9.2,58,14.2,60,19.5,60H25h5.5c5.3,0,10.3-2,13.9-5.5c2.6-2.5,5.6-7,5.6-14V19.5C50,14.6,48.3,10,45.1,6.5z M45.8,40.5
	c0,5.4-2.3,8.8-4.3,10.7c-2.8,2.8-6.7,4.3-11,4.3H25h-5.5c-4.3,0-8.2-1.5-11-4.3c-1.9-1.9-4.3-5.3-4.3-10.7V19.5
	c0-3.7,1.3-7.3,3.7-10c2.8-3.2,7.1-5.1,11.5-5.1H25h5.5c4.5,0,8.7,1.8,11.5,5.1c2.4,2.7,3.7,6.3,3.7,10V40.5z"
			fill={color}
		/>
		<path
			d="M30.5,7.1H25h-5.5c-3.5,0-6.8,1.4-9,4c-2,2.2-3.1,5.2-3.1,8.4v21.1c0,4.6,1.9,7.4,3.5,9c2.2,2.2,5.3,3.4,8.6,3.4H25h5.5
	c3.3,0,6.4-1.2,8.6-3.4c1.6-1.6,3.5-4.4,3.5-9V19.5c0-3.2-1.1-6.1-3.1-8.4C37.3,8.5,34.1,7.1,30.5,7.1z M38.4,40.5
	c0,2.4-0.7,4.3-2.2,5.7c-1.4,1.4-3.5,2.2-5.7,2.2H25h-5.5c-2.3,0-4.3-0.8-5.7-2.2c-1.4-1.4-2.2-3.3-2.2-5.7V19.5c0-2,0.7-3.9,2-5.3
	c1.5-1.7,3.6-2.6,6-2.6H25h5.5c2.4,0,4.5,0.9,6,2.6c1.3,1.4,2,3.3,2,5.3V30L38.4,40.5L38.4,40.5z"
			fill={color}
		/>
	</svg>
);

const localStorageKey = 'cookie_consent';

const CookieConsent = ({ googleMeasurementId, isDark = false }: { googleMeasurementId: string; isDark?: boolean }) => {
	const [isGranted, setIsGranted] = useState<boolean>(
		typeof window !== 'undefined' ? window.localStorage.getItem(localStorageKey) === 'true' : false,
	);
	const [isVisible, setIsVisible] = useState<boolean>(false);
	useEffect(() => {
		setIsVisible(window?.localStorage.getItem(localStorageKey + '_answered') !== 'true');
	}, []);

	const accept = () => {
		setIsGranted(true);
		setIsVisible(false);
		localStorage.setItem(localStorageKey + '_answered', 'true');
	};
	const block = () => {
		setIsGranted(false);
		setIsVisible(false);
		localStorage.setItem(localStorageKey + '_answered', 'true');
	};

	useEffect(() => {
		window.localStorage.setItem(localStorageKey, String(isGranted));
		if (isGranted) {
			setTimeout(() => {
				(function (w, d, s, l, i) {
					w[l] = w[l] || [];
					w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
					var f = d.getElementsByTagName(s)[0],
						j = d.createElement(s),
						dl = l != 'dataLayer' ? '&l=' + l : '';
					//@ts-ignore
					j.async = true;
					//@ts-ignore
					j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
					f.parentNode.insertBefore(j, f);
				})(window, document, 'script', 'dataLayer', googleMeasurementId);
			}, 1000);
		}
	}, [isGranted]);

	return (
		<div
			className={`cookie-consent ${isDark ? 'cookie-consent--dark' : ''} ${isVisible ? 'cookie-consent--visible' : ''}`}
		>
			<div className="cookie-consent__container">
				{isDark && <Logo color="white" />}
				{!isDark && <Logo color="black" />}
				<p>Overtime uses cookies to track your activity.</p>
				<button className="accept" onClick={accept}>
					Accept
				</button>
				<button className="block" onClick={block}>
					Block
				</button>
			</div>
		</div>
	);
};

export default CookieConsent;
