import React from 'react';
import Suspended from '~/src/components/Suspended';
import Html from './Html';
// import { config as faConfig } from '@fortawesome/fontawesome-svg-core';
// faConfig.autoAddCss = false;
// import '@fortawesome/fontawesome-svg-core/styles.css';

export default function App({ assets = {}, children = null }) {
	return (
		<Html assets={assets} title="Overtime">
			<Suspended>{children}</Suspended>
		</Html>
	);
}

function Error({ error }) {
	return (
		<div>
			<h1 /* style={{ color: 'white' }} */>Application Error</h1>
			<pre /* style={{ color: 'white', whiteSpace: 'pre-wrap' }} */>{error.stack}</pre>
		</div>
	);
}
